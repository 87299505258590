.variation-six {
  .mat-form-field-infix {
    font-size: 0.75rem;
    color: customTheme.$dark-secondary-text;
    font-weight: 900;
    width: 150px;
  }

  .mat-select-min-line {
    color: mat.get-color-from-palette(customTheme.$grey-palette, 400);
  }

  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down-black.svg');
    height: 12px;
    width: 12px;
    margin-left: 8px;
    background-repeat: no-repeat;
  }

  .mat-select-arrow {
    border: none;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }
}

.departure-norms,
.forecast-diff {
  .variation-six {
    .mat-form-field-infix {
      border: 1px solid #d9d9d9;
      border-radius: 32px;
      padding: 6px 10px;
      font-weight: 500;
      height: 12px;
      background: #fff;
    }

    .mat-select-arrow-wrapper {
      margin-bottom: 4px;
    }
  }
}

.location-dropdown {
  .mat-form-field-infix {
    width: 151px;
    background: customTheme.$white;
    box-shadow: 0px 1px 2px customTheme.$black-6-opacity,
      0px 1px 3px customTheme.$white-12-opacity;
    border-radius: 32px;
    padding: 10px;
    border: none;
  }

  .mat-select-min-line {
    font-weight: normal;
    color: customTheme.$dark-primary-text;
    font-size: 0.875rem;
  }

  .mat-form-field-type-mat-select {
    .mat-form-field-label {
      font-weight: 500;
      color: customTheme.$dark-primary-text;
    }
  }

  .mat-form-field-label {
    top: 21px;
    left: 10px;
  }
}

.summary-run-date {
  .mat-select-value-text {
    margin-left: 4px;
    color: #000;
    font-size: 12px;
    font-weight: 400;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
  }
}

.compare-run-dropdown {
  .mat-form-field-infix {
    border-top: unset;
  }
  .variation-six .mat-form-field-infix {
    font-size: 0.875rem;
    font-weight: 700;
    width: 164px;
  }
}
