$temp-gradient: linear-gradient(
  to right,
  #9a63bb,
  #3464a7,
  #5791bf,
  #d5e4ef,
  #cfd5d8,
  #f7dcca,
  #e8a988,
  #c76753,
  #a32a31
);
$frisk-gradient: linear-gradient(to right, #75bdbc, #fd7c7e);
$minmax-feels-like-gradient: linear-gradient(
  to right,
  #3464a7,
  #5791bf,
  #cfd5d8,
  #c76753,
  #a32a31
);
$dewpoint-gradient: linear-gradient(to right, #d9d9d9, #587731);
$precip-gradient: linear-gradient(
  to right,
  #cfd5d8,
  #cde9e5,
  #92cac1,
  #52958f,
  #2b645e
);
.temp-gradient {
  background-image: $temp-gradient;
}
.precip-gradient {
  background-image: $precip-gradient;
}
