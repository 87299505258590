@import 'custom_palette';

.variation-two {
  .mat-form-field-infix {
    padding: 0;
    display: flex;
    font-weight: 700;
    width: auto;
    border-top: 5px solid transparent;
  }

  .mat-select-min-line {
    font-family: 'Plus Jakarta Sans';
    font-size: 1rem;
    color: customTheme.$dark-primary-text;
  }

  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down-black.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 18px;
    width: 14px;
    margin-left: 10px;
  }

  .mat-select-arrow {
    border: none;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }
}

.heat-map-summary.component {
  .min-max-temp-dropdown {
    margin-top: -5px;

    .variation-two {
      .mat-form-field-infix {
        border-top: 0px;
      }

      .mat-select-min-line {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-family: inherit;
        font-weight: 700;
      }

      .mat-select-arrow-wrapper {
        background-image: url(/assets/icons/arrow-down-gray.svg);
        height: 12px;
        width: 12px;
        padding-bottom: 4px;
        margin-left: 5px;
      }
    }
  }

  .popup-body {
    .mat-select-value {
      width: 100%;
    }
  }
}

.forecast-analysis-normal-dropdown {
  display: flex;
  gap: 5px;

  .mat-select-value {
    width: 65px;
  }

  .mat-select-arrow-wrapper {
    margin-left: unset;
  }

  .mat-select-min-line {
    font-family: unset;
  }

  .variation-two .mat-form-field-infix {
    font-size: 0.875rem;
    padding: 2px 4px;
    border-top: 0;

    .mat-select-min-line {
      font-size: 0.875rem;
      color: $dark-secondary-text;
    }
  }
}
