@import './mixins';

.forecast-chart-box {
  background: customTheme.$white;
  box-shadow: 0px 1px 2px customTheme.$black-6-opacity,
    0px 1px 3px customTheme.$black-12-opacity;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  &.remove-box-styling {
    background: unset;
    box-shadow: unset;
    border-radius: 0px;
  }

  .left-label {
    width: 205px;
    padding-left: 10px;
    padding-top: 25px;

    .head {
      font-size: 0.875rem;
      color: customTheme.$dark-primary-text;
      margin-bottom: 8px;
      line-height: 17px;
      position: sticky;
      top: 0px;
      background: customTheme.$white;

      &.bold {
        font-weight: 700;
      }

      &.spacing {
        margin-bottom: 20px;
      }

      span {
        color: #a7b0b5;
      }

      &.diff-head {
        font-size: 0.75rem;
      }
    }

    .title {
      font-size: 0.75rem;
      color: customTheme.$dark-primary-text;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }

  .right-chart {
    width: calc(100% - 205px);
  }
}

.days-header,
.hourly-chart-header {
  .highcharts-yaxis-labels {
    display: none;
  }

  .highcharts-background {
    fill: transparent;
  }
}

.forecast-difference-charts-box {
  background: customTheme.$white;
  box-shadow: 0px 1px 2px customTheme.$black-6-opacity,
    0px 1px 3px customTheme.$black-12-opacity;
  border-radius: 4px;

  .divider {
    width: 100%;
    margin-top: 7px;
    margin-left: 1px;
    margin-right: 1px;
    border-bottom: 1px solid #cfd5d8;
  }
}

.avg-temp-frisk-chart-box.component {
  .forecast-chart-box .right-chart {
    width: calc(100% - 185px);
  }

  .highcharts-yaxis-grid {
    path:nth-child(even) {
      display: none;
    }
  }
}

.frisk-xAxis-box,
.min-max-xAxis-box {
  width: 4.237vw;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 7px;
}

.frisk-xAxis-box {
  color: $dark-primary-text;
  border-radius: 5px;
}

.min-max-xAxis-box {
  border-radius: 5px;
  margin-top: 3px;
}

.min-max-temp-frisk-chart-box.component {
  .forecast-chart-box .right-chart {
    width: calc(100% + 100px);
  }
}

.days-header.component,
.iso-country-region-weighted-temperature-charts.component,
.model-weighted-forecast-chart.component,
.weighted-graph-differences-widget-container {
  .date-days {
    line-height: 12.6px;
    height: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Plus Jakarta Sans';
    color: black;
  }

  &.widget {
    @include widget-table-headers;
  }

  .date {
    white-space: nowrap;
  }

  .days {
    font-weight: 700;
  }

  .weekend {
    background-color: getColor($table-colors, 'weekend');
  }
}

.hourly-chart-header.component {
  .time {
    font-size: 0.75rem;
    color: customTheme.$dark-primary-text;
    margin-bottom: 10px;
  }
}

.water-icon-text {
  background-color: white;
  height: 15px;
  width: 22px;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

//To disable the default styling of the used libaray i.e. Highcharts
.forecast-charts-labels {
  /* stylelint-disable-line */
  top: 0px !important;

  span {
    /* stylelint-disable-line */
    top: 0px !important;
  }
}

//To override the opacity for bar charts
.precip-bar-forecast-values {
  opacity: 1 !important;
}

.windbarb-icon {
  left: -3px;
  margin-top: 1px;
  width: 16px;
  height: 16px;
  object-fit: scale-down;
  transform-origin: center 84%;
  position: absolute;
}

//Compare Location Date CSS
.date-box {
  text-align: center;
  color: $dark-secondary-text;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 700;

  .month {
    font-size: 10px;
  }

  .date {
    font-size: 10px;
  }
}
