@import 'shared_module';

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  font-family: customTheme.$defaultFontFamily, sans-serif;
  font-size: 16px;
}

// custom global styles
.fbw-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-center {
  display: grid;
  place-content: center;
}
.not-bold {
  font-weight: 500;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  .active {
    font-weight: 700;
    color: '#000305';
  }
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-italic {
  font-style: italic;
}

.reset-text-transform {
  text-transform: none;
}

.location-scroll-height {
  width: 100%;
  overflow-y: auto;
  min-height: 200px;
}

.location-scroll-height,
.charts.scroll-height {
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #e9e9eb transparent;

  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 6px;
    background: #e9e9eb;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6px;
  }
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-data {
  font-size: 0.875rem;
  color: $dark-secondary-text;
  padding-top: 10px;
  text-align: center;
}

.compare-option.mat-selected:not(.mat-option-multiple) {
  background-color: getColor($select-colors, 'selected') !important;
}

.bold-group-text {
  .mat-optgroup-label {
    font-weight: bold;
  }
}

.is-weekend {
  background-color: getColor($table-colors, 'weekend');
}

.weekend-border-right {
  border-right: 1px solid mat.get-color-from-palette($grey-palette, 600);
}
.weekend-border-left {
  border-left: 1px solid mat.get-color-from-palette($grey-palette, 600);
}
.weekend-border-last-row {
  border-bottom: 1px solid mat.get-color-from-palette($grey-palette, 600);
}

.city-content {
  .is-weekend {
    background-color: getColor($table-colors, 'light-weekend');
  }
}

.insights-details {
  font-size: 0.75rem;
  color: $dark-secondary-text;
  margin-top: 8px;

  .heading {
    font-weight: 700;
  }

  ul {
    margin-top: 8px;
    margin-left: 30px;
    list-style: disc;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.empty-title-error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: #f4dede;
  border: 1px solid #ebbbbe;
  width: 529px;
  height: 34px;
  text-align: left;
  color: #be0d0d;
  .icon {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-duplicate {
    margin-left: 8px;
    margin-top: 8px;
  }
}

.notes-text-image {
  img {
    margin-top: 5px;
    width: 100%;
  }
}
