.forecast-location-popup {
  display: flex;
  gap: 4px;
  border-radius: 4px;

  .col {
    flex: 1;

    .card {
      background: mat.get-color-from-palette(customTheme.$grey-palette, 100);
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 4px;
      font-size: 0.875rem;
      color: customTheme.$dark-secondary-text;

      h3 {
        font-weight: 700;
        font-size: 0.8125rem;
        margin-bottom: 8px;
        color: #0a0909;

        &.top {
          margin-top: 8px;
        }
      }

      .data {
        display: flex;
        justify-content: space-between;

        &:last-child {
          p {
            margin-bottom: 0px;
          }
        }

        p {
          margin-bottom: 10px;
          font-size: 0.6875rem;
        }

        p:nth-child(2) {
          color: customTheme.$dark-primary-text;
        }
      }
    }
  }
}

//forecast main popup
.forecast-main-popup {
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  background-color: customTheme.$white;

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 8px 8px 8px;
    box-shadow: 0px 1px 0px #e5eaef;

    .left {
      h2 {
        font-size: 0.875rem;
        color: customTheme.$dark-primary-text;
        font-weight: 700;

        span {
          font-size: 0.75rem;
          font-weight: normal;
          color: customTheme.$dark-secondary-text;
        }
      }
    }

    .temp {
      font-weight: 700;
      line-height: 22px;
      padding-bottom: 5px;
    }

    ul {
      display: flex;
      gap: 7px;
      font-size: 0.75rem;
      color: customTheme.$dark-secondary-text;
    }

    li:not(:first-child)::before {
      content: '\2022';
      color: mat.get-color-from-palette(customTheme.$grey-palette, 300);
      margin-right: 7px;
    }

    .cross-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;

      .cross-icon {
        stroke: mat.get-color-from-palette(customTheme.$grey-palette, 500);
      }

      stroke: customTheme.$dark-primary-text;
    }
  }
  .popup-body .mat-tab-label {
    font-size: 0.625rem;
    min-width: initial;
    padding-right: 22px;
  }
}

.run-date-info-popup.mat-menu-panel {
  min-width: 340px !important;
}
