.historical-page-title {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-bottom: 5px;

  p {
    margin: 0 5px 1px 0;
  }

  mat-icon {
    cursor: pointer;
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}

.historical-navigation {
  display: block;
  margin-bottom: 5px;
}
