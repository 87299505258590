.text-content-wrapper {
  .TropicalDiscussionHeadline {
    font-size: 14px;
  }

  .Forecaster {
    margin-top: 5px;
    font-weight: 700;
  }
}
