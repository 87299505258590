.closebutton {
    /* NOTE! This URL is relative to the HTML page, not the CSS */
	filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(
		src='../highslide/graphics/close.png', sizingMethod='scale');

	background: none;
	cursor: hand;
}

/* Viewport fixed hack */
.highslide-viewport {
	position: absolute;
    left: expression( ( ( ignoreMe1 = document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft ) ) + 'px' );
	top: expression( ( ignoreMe2 = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ) + 'px' );
	width: expression( ( ( ignoreMe3 = document.documentElement.clientWidth ? document.documentElement.clientWidth : document.body.clientWidth ) ) + 'px' );
	height: expression( ( ( ignoreMe4 = document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight ) ) + 'px' );
}

/* Thumbstrip PNG fix */
.highslide-scroll-down, .highslide-scroll-up {
	position: relative;
	overflow: hidden;
}
.highslide-scroll-down div, .highslide-scroll-up div {
	/* NOTE! This URL is relative to the HTML page, not the CSS */
	filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(
		src='../highslide/graphics/scrollarrows.png', sizingMethod='scale');
	background: none !important;
	position: absolute;
	cursor: hand;
	width: 75px;
	height: 75px !important;
}
.highslide-thumbstrip-horizontal .highslide-scroll-down div {
	left: -50px;
	top: -15px;
}
.highslide-thumbstrip-horizontal .highslide-scroll-up div {
	top: -15px;
}
.highslide-thumbstrip-vertical .highslide-scroll-down div {
	top: -50px;
}

/* Thumbstrip marker arrow trasparent background fix */
.highslide-thumbstrip .highslide-marker {
	border-color: white; /* match the background */
}
.dark .highslide-thumbstrip-horizontal .highslide-marker {
	border-color: #111;
}
.highslide-viewport .highslide-marker {
	border-color: #333;
}
.highslide-thumbstrip {
	float: left;
}

/* Positioning fixes for the control bar */
.text-controls .highslide-controls {
	width: 480px;
}
.text-controls a span {
	width: 4em;
}
.text-controls .highslide-full-expand a span {
	width: 0;
}
.text-controls .highslide-close a span {
	width: 0;
}

/* Special */
.in-page .highslide-thumbstrip-horizontal .highslide-marker {
    border-bottom: gray;
}
