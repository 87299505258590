// override mat-dialog styles
.firebird-dialog-panel {
  .mat-dialog-container {
    padding: 0;
  }

  &.compare-location {
    .mat-dialog-container {
      overflow: hidden;
    }
  }
}

.firebird-dialog-backdrop {
  // background-color: blue;
  opacity: 0;
}

.firebird-dashboard-panel {
  .mat-dialog-container {
    padding: 8px;
    overflow: hidden;
  }
}
.firebird-dash-widget-lib-panel {
  .mat-dialog-container {
    padding: 8px;
    overflow: visible;
  }
}
.contact-us-dialog-panel {
  .mat-dialog-container {
    padding: 0;
    border-radius: 0.625rem;
    border: 0.5px solid
      mat.get-color-from-palette(customTheme.$grey-palette, 300);
    background: mat.get-color-from-palette(customTheme.$grey-palette, 100);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.06),
      0 20px 25px 0 rgba(0, 0, 0, 0.16);
  }
}

.settings-dialog-wrapper {
  .mat-dialog-container {
    overflow-y: hidden;
    padding: 0;

    .dialog-content {
      height: calc(100% - 67px) !important;
    }
  }
}

.city-location-selection-dialog-panel {
  .mat-dialog-container {
    padding: 0 0 0.625rem 0;
    overflow-y: hidden;
  }

  &-widget {
    .locations {
      max-height: 600px;
    }
  }
}
