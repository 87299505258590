@use 'custom_palette' as customTheme;
@use '@angular/material' as mat;

.DiffFromNormal {
  background-color: mat.get-color-from-palette(customTheme.$grey-palette, 200);
  color: customTheme.$dark-primary-text;
}

.DiffFromNormalSB {
  background-color: customTheme.$temp-m4;
  color: customTheme.$white;
}

.DiffFromNormalMB {
  background-color: customTheme.$temp-m3;
  color: customTheme.$white;
}

.DiffFromNormalB {
  background-color: customTheme.$temp-m2;
  color: customTheme.$white;
}

.DiffFromNormalSlightB {
  background-color: customTheme.$temp-m1;
  color: customTheme.$dark-primary-text;
}

.DiffFromNormalSlightA {
  background-color: customTheme.$temp-1;
  color: customTheme.$dark-primary-text;
}

.DiffFromNormalA {
  background-color: customTheme.$temp-2;
  color: customTheme.$dark-primary-text;
}

.DiffFromNormalMA {
  background-color: customTheme.$temp-3;
  color: customTheme.$white;
}

.DiffFromNormalSA {
  background-color: customTheme.$temp-4;
  color: customTheme.$white;
}

.defaultTableCellColor,
.Default,
.DewpointBelow60,
.DewpointBelowSixteen {
  background-color: mat.get-color-from-palette(customTheme.$grey-palette, 100);
}

.hddValue {
  color: #a32a31;
  font-weight: 700;
}

.cddValue {
  color: #3464a7;
  font-weight: 700;
}

.split-view-box {
  .hddValue {
    background-color: #a32a31;
    color: $white;
    font-weight: unset;
  }

  .cddValue {
    background-color: #3464a7;
    color: $white;
    font-weight: unset;
  }
}

.font-color-HighForecastRisk {
  color: #fd7c7e;
}
.font-color-LowForecastRisk {
  color: #75bdbc;
}
.font-color-AverageForecastRisk {
  color: $dark-secondary-text;
}

.LowForecastRisk {
  background-color: #75bdbc;
  .sub-title {
    font-size: 0.625rem;
    font-style: italic;
  }
}

.HighForecastRisk {
  background-color: #fd7c7e;
  .sub-title {
    font-size: 0.625rem;
    font-style: italic;
  }
}

.AverageForecastRisk {
  background-color: #f4f5f6;
  color: #000305;
}

.DiffBelowZero {
  background-color: #b1cde3;
}

.DiffAboveZero {
  background-color: #d9b8ba;
}

.DewpointDiffIsZero {
  background-color: mat.get-color-from-palette(customTheme.$grey-palette, 100);
}

.DewpointDiffLessThenZero {
  background-color: #c7b49a;
}

.DewpointDiffGrtThenZero {
  background-color: #a5b690;
}

.DiffDDWarmer {
  background-color: #d9b8ba;
}

.DiffDDColder {
  background-color: #b1cde3;
}

// .POPDiffBelowZero{

// }

// .POPDiffAboveZero{

// }

.DewpointAbove60,
.DewpointEqualAboveSixteen {
  background-color: #587731;
  color: #ffffff;
}

// Min/Max Feels Like colour
// For Farenheit,Celcius
.FeelsLikeLessEqlMinusSeven,
.FeelsLikeLessEqlMinusFour {
  background-color: #0000c8;
  color: customTheme.$white;
}
.FeelsLikeBetwMinusSevenMinusThree,
.FeelsLikeBetwMinusFourMinusTwo {
  background-color: #4986ff;
  color: customTheme.$white;
}
.FeelsLikeBetwMinusThreePlusThree,
.FeelsLikeBetwMinusTwoPlusTwo {
  background-color: mat.get-color-from-palette(customTheme.$grey-palette, 100);
}
.FeelsLikeGrtThenEqlThreeLessThenSeven,
.FeelsLikeGrtThenEqlTwoLessThenFour {
  background-color: #e11919;
  color: customTheme.$white;
}
.FeelsLikeGrtThenEqlSeven,
.FeelsLikeGrtThenEqlFour {
  background-color: #ab0c0c;
  color: customTheme.$white;
}

// For Forecast Difference
.FeelsLikeLessZero {
  background-color: #b1cde3;
}
.FeelsLikeEqlZero {
  background-color: #cfd5d8;
}
.FeelsLikeGrtZero {
  background-color: #d9b8ba;
}

.HighConfidenceCooler {
  background-color: #3464a7;
  color: white;
}

.MediumConfidenceCooler {
  background-color: #b1cde3;
}

.DefaultConfidence {
  background-color: #f4f5f6;
}

.MediumConfidenceWarmer {
  background-color: #d9b8ba;
}

.HighConfidenceWarmer {
  background-color: #a32a31;
  color: white;
}
