@use '@angular/material' as mat;
@import 'custom_palette';

@mixin fresh-look() {
  .mat-form-field-infix {
    padding: 0.313rem 0.625rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: unset;
  }

  .mat-select-value-text {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: unset;
  }

  .mat-select-arrow-wrapper {
    margin-bottom: 0.125rem;
    width: 12px;
    height: 12px;
  }

  & .mat-select-arrow-wrapper {
    margin-top: 0;
    margin-bottom: 0.125rem;
    padding-bottom: 0;
    transform: rotate(0deg);
    transition: transform 0.1s linear;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    margin-top: 0.375rem;
    transform: rotate(180deg);
  }
}

.multi-dd-start {
  &.fresh-look {
    @include fresh-look;
  }

  .mat-form-field-infix {
    border: 1px solid mat.get-color-from-palette($grey-palette, 425);
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
    padding: 7px 8px;
    font-size: 12px;
    font-weight: 500;
    background-color: $white;
  }

  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down-black.svg');
    background-repeat: no-repeat;
    height: 10px;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }

  .mat-select-arrow {
    margin: 0 8px;
    border: none;
  }

  &.reset-border-radius {
    .mat-form-field-infix {
      border-radius: 32px;
    }
  }
}

.multi-dd-end {
  &.fresh-look {
    @include fresh-look;
  }

  .mat-form-field-infix {
    border: 1px solid mat.get-color-from-palette($grey-palette, 425);
    border-bottom-right-radius: 32px;
    border-top-right-radius: 32px;
    padding: 7px 8px;
    font-size: 12px;
    border-left: none;
    background-color: $white;
    width: 140px;
  }
  .mat-select-value {
    overflow: visible;
  }
  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down-black.svg');
    background-repeat: no-repeat;
    height: 10px;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }

  .mat-select-arrow {
    border: none;
    margin: 0 8px;
  }
}

.single-dd {
  width: 139px;
  .mat-form-field-infix {
    border: 1px solid mat.get-color-from-palette($grey-palette, 425);
    border-radius: 32px;
    padding: 7px 8px;
    font-size: 12px;
    margin-top: 3px;
    width: 112px;
    background-color: $white;
  }
  .mat-select-value {
    overflow: visible;
  }
  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down-black.svg');
    background-repeat: no-repeat;
    height: 10px;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }

  .mat-select-arrow {
    border: none;
    margin: 0 8px;
  }
}

.renew-forecast-dd {
  .mat-form-field-infix {
    border: 1px solid mat.get-color-from-palette($grey-palette, 425);
    border-radius: 12px;
    padding: 7px 8px;
    font-size: 14px;
    margin-top: 3px;
    width: 177px;
    background-color: $white;
  }
  .mat-select-value {
    margin-left: 8px;
    overflow: visible;
  }

  .mat-select-arrow-wrapper {
    margin-right: 8px;
    background-image: url('/assets/icons/arrow-down-black.svg');
    background-repeat: no-repeat;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }

  .mat-select-arrow {
    border: none;
    margin: 0 8px;
  }
}
