@import 'custom_palette';

.ForecastFirskIndexAvgTempGreen {
  background-color: #75bdbc;
}

.ForecastFirskIndexAvgTempPink {
  background-color: #fd7c7e;
}

.ForecastFirskIndexAvgTempGrey {
  background-color: #e2e2e2;
}

.font-color-ForecastFirskIndexAvgTempGreen {
  color: #75bdbc;
}

.font-color-ForecastFirskIndexAvgTempPink {
  color: #fd7c7e;
}

.font-color-ForecastFirskIndexAvgTempGrey {
  color: $dark-secondary-text;
}
