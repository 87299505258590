.variation-one {
  .mat-form-field-infix {
    border: 1px solid;
    border-radius: 32px;
    padding: 6px 10px;
    font-weight: 500;
    font-size: 0.87rem;
    line-height: 19px;
    color: customTheme.$dark-primary-text;
  }

  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down.svg');
    height: 16px;
    width: 16px;
  }

  .mat-select-arrow {
    border: none;
  }
  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }
}
