@use '@angular/material' as mat;
@import 'custom_palette';

.checkbox.component {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;

  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;

    color: #000305;
    width: 16px;
    height: 16px;
    border: 1px solid #000305;
    border-radius: 2px;
    transform: translateY(-0.075em);
    cursor: pointer;
    display: inline-grid;
    place-content: center;

    &.right-gap {
      margin-right: 3px;
    }
    &.left-gap {
      margin-left: 3px;
    }

    &.gray-style {
      color: mat.get-color-from-palette($grey-palette, 300);
      border: 1px solid mat.get-color-from-palette($grey-palette, 425);
    }
    &.widget-decimal {
      color: mat.get-color-from-palette($grey-palette, 50);
      border: 1px solid mat.get-color-from-palette($grey-palette, 300);
    }
    &::before {
      content: '';
      width: 8px;
      height: 9px;
      transform: scale(0);
      transition: 50ms transform ease-in-out;
    }

    .mat-checkbox-ripple {
      display: none;
    }
    .mat-checkbox-frame {
      border-width: 1px !important;
      border-color: #000305;
    }
    .mat-checkbox-background {
      background-color: transparent !important;
    }
    .mat-checkbox-checkmark {
      fill: #000305 !important;
    }
    .mat-checkbox-mixedmark {
      background-color: #000305 !important;
    }
    .mat-checkbox-checkmark-path {
      stroke: #000305 !important;
    }
    &:checked::before {
      transform: scale(1);
      background-image: url('/assets/icons/tick.svg');
      background-repeat: no-repeat;
    }
  }
}
