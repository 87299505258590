.iso-country-region-weighted-temperature-charts.component {
  background-color: $white;

  .temperature-charts {
    background-color: $white;
    display: flex;
    align-items: stretch;
    padding-top: 0px;
    padding-right: 0px;
    margin-bottom: 5px;

    .title {
      font-size: 0.75rem;
      color: $dark-primary-text;
      font-weight: 700;
      margin: 20px 0 20px 6px;
    }

    .labels-dropdown-section {
      display: flex;
      flex-wrap: wrap;
      width: 165px;

      .labels-list {
        ul {
          margin-left: 10px;

          li {
            font-weight: 700;
            font-size: 0.625rem;
            color: $dark-secondary-text;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            max-width: 150px;

            span {
              width: 16px;
              height: 2px;
              display: inline-block;
              margin-right: 5px;

              &.max-temp {
                background-color: $temp-4;
              }

              &.min-temp {
                background-color: $temp-m4;
              }

              &.min-max-temp {
                background-color: mat.get-color-from-palette(
                  $grey-palette,
                  300
                );
                height: 12px;
              }

              &.avg-temp {
                background-color: $dark-primary-text;
              }

              &.avg-normals {
                border-bottom: 2px dashed $dark-primary-text;
              }

              &.max-feels-like {
                background-color: $dark-secondary-text;
              }
            }
          }
        }
      }

      .dropdown {
        align-self: end;
        margin-bottom: 3px;

        .normal {
          display: flex;
          align-items: center;

          span {
            font-size: 0.62rem;
            color: $dark-secondary-text;
          }
        }
      }
    }

    .charts {
      width: calc(100% - 165px);
    }
  }

  .charts-difference {
    background-color: $white;
    display: flex;
    padding-right: 0;

    .labels-section {
      width: 165px;
      display: flex;
      flex-wrap: wrap;
      height: 85px;
      align-items: flex-end;
      margin-top: 5px;

      .title {
        font-size: 0.75rem;
        color: $dark-primary-text;
        font-weight: 700;
      }

      .label {
        font-size: 0.625rem;
        color: $dark-primary-text;
        font-weight: 700;
      }

      .date {
        font-size: 0.625rem;
        color: $dark-secondary-text;
        width: 104px;
        line-height: 13px;
        display: flex;
        flex-wrap: wrap;

        span {
          font-weight: 700;
        }
      }
    }

    .diff-chart {
      width: calc(100% - 165px);
    }
  }

  .diff-chart {
    .highcharts-axis-labels {
      text:nth-child(2) {
        transform: translate(0, 14px);
      }

      text:nth-child(1) {
        transform: translate(0, 25px);
      }
    }
  }
}

.model-weighted-forecast-chart-popup.component {
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0px 1px 0px #e2e6e8;
    background-color: $white;
    align-items: center;

    .heading {
      font-size: 20px;
      color: #000305;
      font-weight: 700;
      margin-left: 10px;
    }

    .close {
      display: flex;
      cursor: pointer;
      padding: 7.5px;
      box-shadow: 0px 1px 2px $black-6-opacity, 0px 1px 3px $black-12-opacity;

      .icon {
        background-image: url('/assets/icons/cross.svg');
        width: 12px;
        height: 12px;
        background-position: center;
      }
    }
  }
}

.model-weighted-forecast-chart-widget-popup.component {
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0px 1px 0px #e2e6e8;
    background-color: $white;
    align-items: center;

    .heading {
      font-size: 20px;
      color: #000305;
      font-weight: 700;
      margin-left: 10px;
    }

    .close {
      display: flex;
      cursor: pointer;
      padding: 7.5px;
      box-shadow: 0px 1px 2px $black-6-opacity, 0px 1px 3px $black-12-opacity;

      .icon {
        background-image: url('/assets/icons/cross.svg');
        width: 12px;
        height: 12px;
        background-position: center;
      }
    }
  }
}

//main-box-styling
.main-box-styling {
  .divider {
    margin: 7px 0px;
  }
}

//box styling
.heat-map-box-styling {
  .ag-header-cell {
    align-items: flex-end;
  }

  .heat-map-box {
    margin: 0px 0 6px 0;

    .title {
      font-size: 0.75rem;
      color: $dark-primary-text;
      margin: 5px 0 6px 0;
    }

    .box {
      background-color: $white;
      width: 100%;
      border-radius: 4px;

      .right-spacing {
        box-sizing: border-box;
      }
    }

    .forecast-heat-map-header {
      display: flex;
      justify-content: space-between;
      padding: 5px 0 5px;

      .export-button {
        margin-top: -5px;
      }
    }
  }
}
