// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@use './themes/custom_palette' as customTheme;
@import 'custom_palette';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(customTheme.$grey-palette);
$app-accent: mat.define-palette(customTheme.$blue-palette, 700);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(customTheme.$red-palette, 400);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: customTheme.$defaultFontFamily,
      ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: customTheme.$defaultFontFamily, sans-serif;
  font-size: 16px;
}
.custom-select-loc {
  width: 221px;
  // height:200px;
  font-size: 13px;
  font-weight: 400;
}
.custom-author-select {
  margin-top: 40px;
  margin-left: 20px;
  width: 145px;
  font-size: 14px;
}
.add-remove {
  .additional-btn {
    color: black !important;

    &:hover {
      color: mat.get-color-from-palette($grey-palette, 450) !important;
    }
  }
}
.blog-content {
  * {
    max-width: 100%;
  }
}
.locations-grid.selected {
  .cdk-drag-placeholder {
    max-height: 0px;
    border: 1px solid;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.ag-header-cell-comp-wrapper {
  height: 100%;
}

.permission-denied {
  color: #c9c9c9 !important;
}

@import './reset';
@import './dropdown';
@import './checkbox';
@import './dialog';
@import './gradients';
@import './mat-overrides';
@import './global';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-blue.css';
@import '~nouislider/dist/nouislider.min.css';
@import './heat-map';
@import './forecast-detailed-discussion';
@import './color-codes/temperature';
@import './color-codes/percipitation';
@import './color-codes/intensities';
@import './forecast-charts';
@import './popup';
@import './color-codes/frisk-index';
@import './range-slider';
@import './weighted';
@import './widget-panel';
@import './mixins';
@import './widgets';
@import './text-viewer';
@import './historical';
