.close-save {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;

  .save {
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 500;
    width: 37px;
    height: 15px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 15px;
    border-radius: 17px;
    box-shadow: 0px 1px 2px 1px #0000000f;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: mat.get-color-from-palette($blue-palette, 650);
    }
  }
  .cancel {
    cursor: pointer;
    font-family: Plus Jakarta Sans;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    align-self: center;
    color: #525252;
    &:hover {
      color: #000;
    }
  }
}
.widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  position: sticky;
  margin: 5px;
}
.drag-class {
  flex-grow: 1;
  height: 23px;
  cursor: move;
}
.edit-btn,
.delete-btn,
.copy-btn {
  color: mat.get-color-from-palette($grey-palette, 425);
  &:hover {
    color: mat.get-color-from-palette($blue-palette, 650);
  }
}

.share-btn {
  color: mat.get-color-from-palette($grey-palette, 425);
  cursor: default;
}

.widget-decimal {
  font-size: 12px;
  font-weight: 400;
  color: mat.get-color-from-palette($grey-palette, 450);
}
ng-scrollbar {
  --scrollbar-size: 8px;
  --scrollbar-thumb-color: rgba(128, 128, 128, 0.5);
  --scrollbar-hover-size: 8px;
  --scrollbar-track-color: rgba(0, 0, 0, 0.05);
  --scrollbar-border-radius: 10px;
}
