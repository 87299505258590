.variation-four {
  .mat-form-field-infix {
    border: 1px solid #d9d9d9;
    border-radius: 32px;
    padding: 6px 10px;
    font-weight: 500;
    font-size: 0.87rem;
    height: 12px;
    line-height: 19px;
    background: #fff;
  }

  // .mat-select-min-line {
  //   color: customTheme.$light-primary-text;
  //   font-size: 12px;
  // }

  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down-dark.svg');
    height: 12px;
    width: 12px;
    margin-left: 10px;
    margin-bottom: 12px;
  }

  .mat-select-arrow {
    border: none;
    stroke: #000;
  }
  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }
  .mat-select-value-text {
    color: #000;
    position: relative;
    bottom: 5px;
    font-size: 12px;
    font-weight: 400;
  }
}

//Panel class
.variation-four-panelClass {
  &.mat-select-panel {
    max-height: unset;
  }
}

// Summary table widget config panel forecast dropdown
.summary-widget-forecast-dropdown {
  &.mat-select-panel {
    max-height: 40dvh !important;
  }
}
