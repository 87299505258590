.variation-three {
  .mat-form-field-infix {
    padding: 0 10px;
    display: flex;
    font-weight: 700;
    width: auto;
  }

  .mat-select-min-line {
    font-size: 1.5rem;
    color: customTheme.$dark-primary-text;
  }

  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-up.svg');
    height: 12px;
    width: 12px;
    margin-left: 10px;
  }

  .mat-select-arrow {
    border: none;
  }

  .cities-dropdown {
    .region-cities {
      font-size: 0.75rem;
      margin-top: 5px;
      text-transform: uppercase;
      font-weight: 900;
      color: customTheme.$dark-secondary-text;
    }
  }
  svg {
    fill: mat.get-color-from-palette(customTheme.$grey-palette, 600);
  }
  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }
}
