//override nouislider range slider style
.noUi-horizontal .noUi-handle {
  width: 0px;
  border: 0px;
  right: 3px;
}
.noUi-target {
  border: 0px;
  background: none;
  border-radius: 0px;
  box-shadow: none;
}
.noUi-draggable {
  cursor: unset;
}
.noUi-connect {
  background: mat.get-color-from-palette($blue-palette, 700);
  height: 2px;
  margin-top: 16px;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
