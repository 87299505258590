.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.mat-form-field {
  font-weight: 500;
}

.custom-chip-tooltip {
  background-color: #333;
  color: #fff;
  font-size: 12px;
  padding: 8px 16px;
  line-height: 1.7;
  border-radius: 4px;
}

/*Autocomplete Mat Global Styling Start*/
.autocomplete-container {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    border: 1px solid mat.get-color-from-palette(customTheme.$grey-palette, 300);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 19px;
    color: customTheme.$dark-primary-text;
  }

  .mat-form-field-label-wrapper {
    margin-left: 12px;
  }

  .autocomplete-searchbox {
    .mat-form-field-infix {
      border-radius: 32px;
      padding: 6px 10px;
      background: #fff;
      width: 239px;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.products-search-box {
  .mat-form-field-infix {
    width: 550px;
  }
}

.autocomplete-box {
  &.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
      0px 10px 10px rgba(0, 0, 0, 0.04);
  }

  .mat-option.mat-active,
  .mat-option {
    height: 31px;
    color: mat.get-color-from-palette(customTheme.$grey-palette, 450);
    font-size: 0.875rem;
  }

  .mat-option.mat-active,
  .mat-option:hover:not(.mat-option-disabled) {
    background-color: mat.get-color-from-palette(
      customTheme.$grey-palette,
      100
    );
    color: mat.get-color-from-palette(customTheme.$grey-palette, 500);
    font-weight: bold;
  }
}

.mat-select-panel {
  &:not([class*='mat-elevation-z']) {
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
      0px 10px 10px rgba(0, 0, 0, 0.04);
  }

  .mat-option {
    .mat-icon {
      margin-right: 0;
    }
  }

  .dropdown-box {
    .mat-option {
      height: 31px;
      color: customTheme.$dark-secondary-text;
      font-size: 0.875rem;

      &.mat-active,
      &:hover:not(.mat-option-disabled) {
        background-color: mat.get-color-from-palette(
          customTheme.$grey-palette,
          100
        );
        color: mat.get-color-from-palette(customTheme.$grey-palette, 500);
        font-weight: bold;
      }
    }
  }
}

.windfarm-picker {
  .autocomplete-container .autocomplete-searchbox .mat-form-field-infix {
    width: 100%;
    font-size: 11px;
    line-height: normal;
  }
}

// mat toggle tabs
.toggle-tabs {
  .mat-button-toggle-appearance-standard {
    font-size: 14px;
    color: #000; // mat.get-color-from-palette(customTheme.$grey-palette, 400);
    background: #fff;
    // border:1px solid mat.get-color-from-palette(customTheme.$grey-palette);

    .icons {
      stroke: #000;
      padding-right: 4px;
      top: 1px;
    }

    .mat-button-toggle-label-content {
      padding: 6px 10px;
      line-height: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      //vertical-align:bottom;
    }
    .mat-button-toggle-label-content > * {
      position: relative;
      bottom: 1px;
    }
  }

  &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  &.mat-button-toggle-group-appearance-standard {
    border-radius: 32px;
    border: 0px;
    border: 1px solid #d9d9d9;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: #fff;
  }

  .mat-button-toggle-checked {
    background-color: #2d69b2;

    .icons {
      stroke: mat.get-color-from-palette(customTheme.$grey-palette, 500);
      padding-right: 4px;
      position: relative;
      top: 1px;
    }
  }

  .mat-button-toggle-disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: #eeeeee;
  }
}

.standart-export-button {
  .mat-button-toggle-appearance-standard {
    font-size: 12px;
    font-weight: 500;

    .mat-button-toggle-label-content {
      padding: 4px 10px;
    }

    .icons {
      padding-right: 8px;
    }
  }
}

.weighted-export-button {
  .mat-button-toggle-group-appearance-standard {
    border: none;
    border-radius: 0;
  }
  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-button {
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 12px;
      border-radius: 32px;
      background: none;
      border: 1px solid #d9d9d9;
      padding: 0;

      .mat-button-toggle-label-content {
        padding: 2px 10px !important;
        line-height: 19px;
      }
    }
  }
}

.weighted-toggle-tabs {
  .toggle-tabs .mat-button-toggle-appearance-standard {
    font-size: 12px;

    .mat-button-toggle-label-content {
      line-height: 14px;
    }
  }
}

.weighted-dropdowns {
  .variation-five {
    .mat-form-field-infix {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.toggle-tabs-renewables {
  .mat-button-toggle-appearance-standard {
    font-size: 14px;
    color: #000;
    background: #fff;

    .icons {
      stroke: #000;
      padding-right: 4px;
      top: 1px;
    }

    .mat-button-toggle-label-content {
      padding: 6px 10px;
      line-height: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      //vertical-align:bottom;
    }
    .mat-button-toggle-label-content > * {
      position: relative;
      bottom: 1px;
    }
  }

  &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  &.mat-button-toggle-group-appearance-standard {
    border-radius: 32px;
    border: 0px;
    border: 1px solid #d9d9d9;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: #fff;
  }

  .mat-button-toggle-checked {
    background-color: #2d69b2;

    .icons {
      stroke: mat.get-color-from-palette(customTheme.$grey-palette, 500);
      padding-right: 4px;
      position: relative;
      top: 1px;
    }
  }

  &.mat-button-toggle-group-appearance-standard {
    .mat-button-toggle + .mat-button-toggle {
      border: 0px;
    }
  }
}

.forecast-heat-map-header {
  .toggle-tabs {
    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        padding: 0px 10px;
      }
    }
  }
}

.forecast-daily-header {
  .heat-map-header {
    background-color: mat.get-color-from-palette($grey-palette, 100);

    &.is-weekend {
      background-color: #fff !important;
    }
  }
}

// affordance
.affordance-menu-panel {
  width: 198px;

  &.mat-menu-panel {
    min-height: unset;
  }

  .fixed-menu {
    border-top: 1px solid
      mat.get-color-from-palette(customTheme.$grey-palette, 300);
    background-color: mat.get-color-from-palette(
      customTheme.$grey-palette,
      100
    );
  }

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-menu-item {
    color: mat.get-color-from-palette(customTheme.$grey-palette, 400);

    &:hover {
      color: mat.get-color-from-palette(customTheme.$grey-palette, 500);
      font-weight: 700;
    }
  }
}
.renewables-menu-panel {
  &.mat-menu-panel {
    max-height: 357px;
    position: relative;
    margin-top: 4px;
    right: 4px;
  }
}
.favorite-location-menu-panel {
  width: 185px;
  font-size: 0.875rem;
  line-height: 19px;
  padding: 6px 0px 0px 20px;

  .button-panel {
    display: flex;
    gap: 20px;
  }
}

// Override material dropdown styles
.mat-form-field-underline {
  display: none;
}

.cdk-overlay-container
  .cdk-overlay-pane
  .mat-select-panel-wrap
  > .mat-pseudo-checkbox
  .variation-four-panelClass {
  border-radius: 50%;
}

// override profile, notification and setting mat-menu.
.profile-menu-panel {
  .mat-menu-content:not(:empty) {
    padding: 0;
  }
}

// popup style over ride
.popup-body {
  .toggle-tabs.mat-button-toggle-group-appearance-standard {
    box-shadow: none;
    border-radius: 0;
  }

  .toggle-tabs .mat-button-toggle-appearance-standard {
    background-color: transparent;
  }

  .toggle-tabs .mat-button-toggle-label-content {
    padding: 10px 0;
    margin-right: 20px;
    margin-bottom: 5px;
  }

  .variation-one .mat-form-field-infix {
    border: none;
    padding: 6px 0;
  }

  .mat-select-value {
    font-weight: 500;
    width: 63%;
  }

  .mat-tab-label {
    padding: 0px;
    height: 28px;
    justify-content: left;
    font-size: 0.8125rem;
  }

  .mat-ink-bar {
    background-color: transparent !important;
  }

  .mat-tab-header {
    border: none;
  }

  .mat-tab-label-active {
    opacity: 1;
    font-weight: 700;
  }
}

.dropdownVariationClass {
  margin-top: 25px;
  margin-bottom: 25px;

  .mat-select-panel {
    max-height: 360px;
  }

  .mat-select-panel.na-renewables {
    max-width: none;
  }

  .mat-option.mat-option-disabled {
    .mat-option-text {
      color: mat.get-color-from-palette(customTheme.$grey-palette, 425);
    }
  }
}

.news-filter-select-overlay {
  margin-top: 2.5rem;
  margin-left: 1.25rem;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border: none;
}

.model-navigation,
.all-products-navigation,
.probabilistic-frisk-navigation {
  .mat-tab-link-container {
    z-index: 0;
  }

  .mat-tab-link {
    //opacity: 1;
    background: mat.get-color-from-palette(customTheme.$grey-palette, 100);
    min-width: 126px;
    height: 56px;
    padding: 0px;
    text-decoration: none;
    // color: mat.get-color-from-palette(customTheme.$grey-palette, 400);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    border: 1px solid mat.get-color-from-palette(customTheme.$grey-palette, 100);
    border-bottom: 0px;
    box-sizing: border-box;

    &:hover {
      background: #f5f7f9;
      border: 1px solid #e5eaef;
      font-weight: 700;
      border-bottom: 0px;
      color: customTheme.$dark-primary-text;
    }
  }

  .mat-tab-label-active {
    background: #f5f7f9;
    border: 1px solid #e5eaef;
    font-weight: 700;
    border-bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    color: customTheme.$dark-primary-text;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}

.probabilistic-frisk-navigation {
  .mat-tab-links {
    gap: 10px;
  }

  .mat-tab-link {
    width: 100%;
    min-width: auto;
  }
}

.forecast-verification {
  .header {
    button.secondary-btn {
      border: unset;
      padding: 7px 10px;
    }
  }
}

.maps.component {
  .header {
    .variation-five {
      margin-right: 4px;

      .mat-form-field-infix {
        width: 100%;
        min-width: auto;
        margin: 0;
      }
      .mat-select-trigger {
        margin-right: 21px;
        align-items: normal;
      }

      &.mat-focused .mat-select-arrow-wrapper {
        padding-bottom: 3px;
      }
    }
  }
}

.default-dropdown {
  .variation-five {
    .mat-form-field-infix {
      font-size: 12px;
      line-height: 12px;
      padding: 5px 12px;
    }
  }
}

.region-weighted-temperature-panel {
  &.mat-menu-panel .mat-menu-item {
    height: 30px;
    line-height: unset;
  }
}

//Toggle tabs for weighted module forecast
.model-single-forecast {
  .tabs {
    .toggle-tabs {
      font-size: 0.875rem;
    }
  }
}

.mat-snack-bar-container {
  background-color: white;
  padding: 33px !important;
  color: #000305;
}

.renewables-menu-panel,
.maps-menu-panel {
  .mat-menu-item .mat-icon {
    margin-right: 0;
  }
}

.locations-menu-panel,
.mat-menu-panel {
  min-width: 160px !important;

  .mat-menu-item {
    &.active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .mat-menu-item .mat-icon {
    margin-right: 0;
  }
}

.toggle-group {
  .mat-button-toggle-label-content {
    line-height: 25px;
  }
}

firebird-web-cluster-filters {
  mat-button-toggle-group {
    height: 24px;

    span {
      line-height: 12px !important;
    }
  }
}

.model .navigation-tabs .mat-tab-links .mat-tab-link {
  line-height: 0 !important;
}

.mat-checkbox-ripple {
  display: none;
}
.mat-checkbox-frame {
  border-width: 1px !important;
  border-color: #000305;
}
.mat-checkbox-background {
  background-color: transparent !important;
}
.mat-checkbox-checkmark {
  fill: #000305 !important;
}
.mat-checkbox-mixedmark {
  background-color: #000305 !important;
}
.mat-checkbox-checkmark-path {
  stroke: #000305 !important;
}

.toggle-tabs {
  &.small-tabs {
    .mat-button-toggle-appearance-standard {
      border-right: 1px solid mat.get-color-from-palette($grey-palette, 250);
    }

    &.mat-button-toggle-group-appearance-standard {
      .mat-button-toggle-label-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.375rem 0.625rem;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 13.75px;
      }

      .mat-button-toggle + .mat-button-toggle:not(:last-child) {
        border-right: 1px solid mat.get-color-from-palette($grey-palette, 250);
      }
    }
  }
}

.historical {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }
}

.variation-five {
  &__option {
    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.weighted.latest {
      height: 3rem !important;

      .mat-option-text {
        display: grid;

        & > p {
          display: inline-block;
          line-height: 20px;

          &:first-child {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.historical,
.master-city-list {
  .ag-theme-material {
    font-size: 0.75rem;

    .ag-header-row {
      border-bottom: 1px solid
        mat.get-color-from-palette(customTheme.$grey-palette, 425);
    }

    .ag-header-group-cell:not(:last-of-type, :first-of-type) {
      border-right: 1px solid
        mat.get-color-from-palette(customTheme.$grey-palette, 425);
    }

    .ag-row,
    .ag-header-row {
      .border-right-cell:not(:last-of-type) {
        border-right: 1px solid
          mat.get-color-from-palette(customTheme.$grey-palette, 425);
      }
    }

    .ag-header-group-cell-label,
    .ag-header-cell:not(.align-left) .ag-header-cell-label {
      justify-content: center;
    }

    .ag-cell {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .text-bold {
      font-weight: 700;
    }

    .ag-header-cell {
      .ag-header-cell-comp-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .ag-header-cell-text,
    .ag-header-group-text {
      line-height: normal;
    }

    .ag-cell:not(.date-cell) {
      text-align: center;
    }
  }
}

.model-mat-menu-panel {
  &.mat-menu-panel {
    max-width: 800px;
  }

  & .mat-menu-content {
    padding: 1rem 1.625rem 1.625rem 1.625rem !important;
  }
}

.na-renewables-forecast-times-mat-menu-panel {
  &.mat-menu-panel {
    max-width: 450px;
  }

  & .mat-menu-content {
    padding: 0 !important;
  }
}

.content__contacts {
  & .mat-tab-labels {
    gap: 0 1.25rem;
  }
  & .mat-tab-label {
    min-width: 0;
    padding: 0;
    justify-content: start;
    height: auto;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 20.16px;
    border-bottom: 2px solid transparent;
    opacity: 1;
    flex-basis: auto !important;
    flex-grow: 0 !important;

    &.mat-tab-label-active {
      border-bottom: 2px solid
        mat.get-color-from-palette(customTheme.$blue-palette, 650);
    }
  }
}

.fresh-look-button-toggle {
  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.313rem 0.625rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 13.51px;
    }
  }

  &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  &.mat-button-toggle-group-appearance-standard {
    border-radius: 2rem;
    border: 1px solid mat.get-color-from-palette(customTheme.$grey-palette, 425);
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: mat.get-color-from-palette(customTheme.$grey-palette, 50);
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette(
      customTheme.$blue-palette,
      650
    );
  }
}

.forecast-evolution-button-toggle {
  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.25rem;
      padding: 0.188rem 0.438rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.2em;

      & .toggle-button-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  &.mat-button-toggle-group-appearance-standard {
    border-radius: 2rem;
    border: 1px solid mat.get-color-from-palette(customTheme.$grey-palette, 425);
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: mat.get-color-from-palette(customTheme.$grey-palette, 50);
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette(
      customTheme.$blue-palette,
      650
    );
  }
}

.mat-menu-item {
  &.selected {
    background: rgba(0, 0, 0, 0.04);
  }
}
