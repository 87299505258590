@use '@angular/material' as mat;
@import 'custom_palette';

@mixin content-surface {
  @content;
  border-radius: 0.5rem;
  border: 1px solid mat.get-color-from-palette($grey-palette, 300);
  background-color: mat.get-color-from-palette($grey-palette, 50);
  overflow: auto;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin for-firefox {
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin widget-table-headers {
  .date-days {
    font-size: 10px;
  }
  .date {
    font-weight: 400;
    line-height: normal;
  }
  .days {
    color: mat.get-color-from-palette($grey-palette, 450);
    font-size: 9px;
  }
}
