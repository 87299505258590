@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap');

/****************************** COLOR VARIABLES ******************************/
// @deprecated renamed to $dark-primary-text.
// @breaking-change 8.0.0
$black-87-opacity: rgba(black, 0.87);
// @deprecated renamed to $light-primary-text.
// @breaking-change 8.0.0
$white-87-opacity: rgba(white, 0.87);
// @deprecated use $dark-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$black-12-opacity: rgba(black, 0.12);
// @deprecated use $light-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$white-12-opacity: rgba(white, 0.12);
// @deprecated use $dark-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$black-6-opacity: rgba(black, 0.06);
// @deprecated use $light-[secondary-text,disabled-text,dividers,focused] instead.
// @breaking-change 8.0.0
$white-6-opacity: rgba(white, 0.06);

$white: #ffffff;
$dark-primary-text: #000305;
$dark-secondary-text: #525252;
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);
$red-error-text: #be0d0d;
$red-error-background: #f4dede;
$red-error-icon: #ce4b4b;
$red-error-border: #ebbbbe;

$grey-palette: (
  50: #ffffff,
  80: #fbfbfc,
  90: #fcfcfc,
  100: #f7f8f8,
  150: #f2f2f2,
  200: #f4f5f6,
  250: #e0e0e0,
  275: #e2e6e8,
  300: #cfd5d8,
  400: #6c7c84,
  415: #757575,
  425: #d9d9d9,
  475: #afafaf,
  450: #525252,
  500: #000305,
  600: #000000,
  700: #000001,
  800: #000002,
  900: #000003,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$blue-palette: (
  50: #e4f1fa,
  100: #bcdcf3,
  200: #95c6eb,
  300: #6fb0e2,
  400: #55a0dd,
  500: #4090d7,
  600: #3883ca,
  650: #2d69b2,
  700: #3071b8,
  750: #1e4a80,
  800: #2961a6,
  900: #1d4587,
  950: #05143a,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$red-palette: (
  50: #f9e8e6,
  100: #fbc9bb,
  200: #f8a58f,
  300: #f58264,
  400: #f46743,
  500: #f24b24,
  600: #e84520,
  700: #da3f1c,
  800: #cc3818,
  900: #b32b0f,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: white,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Neutral Data colors
$neutral-1: #bce4d2;
$neutral-2: #75bdbc;
$neutral-3: #157378;

// Temperature data colors
$temp-m4: #9154b0;
$temp-m3: #0000c8;
$temp-m2: #4986ff;
$temp-m1: #add8e6;
$temp-1: #fae6be;
$temp-2: #fa9632;
$temp-3: #e11919;
$temp-4: #ab0c0c;

// Precipitation data colors
$precip-m4: #83541e;
$precip-m3: #b6843d;
$precip-m2: #dac285;
$precip-m1: #f4e8c6;
$precip-1: #cde9e5;
$precip-2: #92cac1;
$precip-3: #52958f;
$precip-4: #2b645e;

// Solar data colors
$solar-m4: #4d4d4d;
$solar-m3: #878787;
$solar-m2: #babbba;
$solar-m1: #dfe0e0;
$solar-1: #f9d4b3;
$solar-2: #f4ba7c;
$solar-3: #f19c43;
$solar-4: #ee8031;

// Wind and load data colors
$wind-m4: #0f6a7d;
$wind-m4: #2da6bf;
$wind-m4: #73c7d9;
$wind-m4: #bce5ee;
$wind-1: #dec2d7;
$wind-2: #cc95bf;
$wind-3: #b963a6;
$wind-4: #851d6f;

/****************************** TYPOGRAPHY VARIABLES ******************************/
$defaultFontFamily: 'Plus Jakarta Sans';
