.extraExtremeIntensity {
  background-color: #5f66ae;
  color: #fff;
}
.extremeIntensity {
  background-color: #a32a31;
  color: #fff;
}
.middleIntensity {
  background-color: #ee8031;
}
.lowIntensity {
  background-color: #e9ce87;
}
.extraLowIntensity {
  background-color: #bce4d2;
}
.zeroIntensity {
  background-color: #cfd5d8;
}
.defaultIntensity {
  background-color: mat.get-color-from-palette(customTheme.$grey-palette, 100);
}

// Renewables Forecast Difference
.lowestNegative {
  background-color: #3464a7;
  color: #ffffff;
}

.lowNegative {
  background-color: #b1cde3;
  color: #000000;
}

.middleNegative {
  background-color: #d9d9d9;
  color: #000000;
}

.highNegative {
  background-color: #d9b8ba;
  color: #000000;
}

.highestNegative {
  background-color: #a32a31;
  color: #ffffff;
}

.max-load,
.maxLoad {
  background-color: #4f8e5c;
  color: mat.get-color-from-palette(customTheme.$grey-palette, 100);
}

.default-load {
  background-color: #f2f2f2;
}

.SolidLegend {
  width: 17px;
  height: 17px;
  background-color: #8cffa0;
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-image: url(/assets/chart-legend/Solid.svg);
  -webkit-mask-image: url(/assets/chart-legend/Solid.svg);
}

.LongDashLegend {
  width: 17px;
  height: 17px;
  background-color: #8cffa0;
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-image: url(/assets/chart-legend/LongDash.svg);
  -webkit-mask-image: url(/assets/chart-legend/LongDash.svg);
}

.ShortDashLegend {
  width: 17px;
  height: 17px;
  background-color: #8cffa0;
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-image: url(/assets/chart-legend/ShortDash.svg);
  -webkit-mask-image: url(/assets/chart-legend/ShortDash.svg);
}

.ShortDotLegend {
  width: 17px;
  height: 17px;
  background-color: #8cffa0;
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-image: url(/assets/chart-legend/ShortDot.svg);
  -webkit-mask-image: url(/assets/chart-legend/ShortDot.svg);
}

.Solid-circleLegend {
  width: 17px;
  height: 17px;
  background-color: #8cffa0;
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-image: url(/assets/chart-legend/Solid-circle.svg);
  -webkit-mask-image: url(/assets/chart-legend/Solid-circle.svg);
}
