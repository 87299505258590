.legend-hover {
  margin-top: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  height: 26px;
  width: 28px;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
.widget-legend {
  position: relative;
  z-index: 2;
  border-radius: 4px;
}
.widget-frisk-legend {
  width: 281px;
  height: 81px;

  .frisk-legend-title {
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-weight: 700;
    line-height: 16.38px;
    text-align: center;
  }
  .frisk-color-codes {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

.custom-tooltip {
  width: 310px;
  font-family: 'Plus Jakarta Sans';
  background-color: $white;
  border-radius: 4px;
  padding: 10px 20px;

  .head-text {
    font-size: 0.8125rem;
    color: $dark-secondary-text;
    margin-bottom: 4px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 8px;
  }

  .labels-data {
    margin-top: 15px;
    .labels-value {
      display: flex;
      justify-content: space-between;
      margin-bottom: 11px;
      font-size: 0.75rem;
      color: $dark-secondary-text;
      .labels {
        display: flex;
        align-items: center;
        span {
          width: 17px;
          height: 2px;
          display: inline-block;
          margin-right: 5px;
          &.max-temp {
            background-color: $temp-4;
          }

          &.min-temp {
            background-color: $temp-m4;
          }

          &.min-max-normals {
            background-color: mat.get-color-from-palette($grey-palette, 300);
            height: 12px;
          }

          &.avg-temp {
            background-color: $dark-primary-text;
          }

          &.avg-normals {
            border-bottom: 2px dashed $dark-primary-text;
          }

          &.max-feels-like {
            background-color: $dark-secondary-text;
          }
          &.avg-temp-diff {
            border-bottom: 2px dashed #ba5f64;
          }
        }
      }
      .value {
        font-weight: 700;
      }
    }
  }
}

.custom-tooltip-weighted-model-forecast {
  font-family: 'Plus Jakarta Sans';
  background-color: $white;
  padding: 6px 22px 0px 8px;

  .popup-header {
    text-align: center;
    color: $dark-secondary-text;
    font-size: 12px;
    font-weight: 700;

    div {
      margin-bottom: 4px;
    }
  }

  .temperature-data {
    .data {
      display: flex;
      font-size: 11px;
      font-weight: 700;
      color: $dark-secondary-text;
      margin-bottom: 9px;
      justify-content: space-between;

      .left {
        display: flex;

        .icon {
          height: 10px;
          width: 20px;
          margin-right: 13px;
        }

        .text {
          margin-right: 20px;
        }
      }
    }
  }
}

.weighted-model-chart-wrapper {
  background-color: white;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  justify-content: space-between;
  width: 100%;

  .columnWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .groupWrapper {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 8px;
    width: 100%;
    display: block;
  }

  .hideGroup {
    display: none;
  }
}
