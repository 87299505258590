.DiffFromPrevPopMedium {
  background-color: #8ed39c;
}

.DiffFromPrevPopLarge {
  background-color: #4f8e5c;
  color: customTheme.$white;
}

.DiffFromPrevPopSmall {
  background-color: mat.get-color-from-palette(customTheme.$grey-palette, 100);
}

.POPDiffAboveZero {
  background-color: #aac6b0;
}

.POPDiffBelowZero {
  background-color: #e1d7cb;
}

// for precep amount
.PrecipAmountInchRange1,
.PrecipAmountMMRange1 {
  background-color: #f4f5f6;
}

.PrecipAmountInchRange2,
.PrecipAmountMMRange2 {
  background-color: #cde9e5;
}

.PrecipAmountInchRange3,
.PrecipAmountMMRange3 {
  background-color: #92cac1;
}

.PrecipAmountInchRange4,
.PrecipAmountMMRange4 {
  background-color: #52958f;
  color: customTheme.$white;
}

.PrecipAmountInchRange5,
.PrecipAmountMMRange5 {
  background-color: #2b645e;
  color: customTheme.$white;
}

.PrecipAmountDiffRange1 {
  background-color: #c7b49a80;
}

.PrecipAmountDiffRange2 {
  background-color: #8faca9;
}
