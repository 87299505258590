@import 'custom_palette';

.variation-five {
  .mat-form-field-infix {
    background: mat.get-color-from-palette(customTheme.$grey-palette, 50);
    width: auto;
    // box-shadow: 0 1px 2px #0000000f, 0 1px 3px #0000001a;
    border-radius: 32px;
    border: 1px;
    font-size: 0.875rem;
    padding: 6px 12px;
    margin-top: 3px;
    display: flex;
    align-items: center;
    line-height: 19px;
    border: 1px solid #d9d9d9;
  }

  &.short-dropdown {
    .mat-form-field-infix {
      padding: 1px 12px;
    }
  }

  &:not(:has(.mat-select-disabled)) {
    & .mat-select-min-line {
      color: #000305;
    }
  }

  .mat-select-arrow-wrapper {
    background-image: url('/assets/icons/arrow-down-gray.svg');
    background-repeat: no-repeat;
    height: 12px;
    width: 12px;
    padding-bottom: 6px;
    margin-left: 10px;
  }

  .mat-select-arrow {
    border: none;
  }

  &.mat-focused .mat-select-arrow-wrapper {
    transform: rotate(180deg);
  }
}

.variation-five-panelClass,
.weighted-dropdown-panelClass {
  &.mat-select-panel {
    max-height: unset;
  }
}

.forecast-values-normal-dropdown {
  .variation-five .mat-form-field-infix {
    font-size: 0.6875rem;
    padding: 2px 4px;
    margin-left: 5px;
  }

  .variation-five {
    span {
      font-size: 0.625rem;
      color: #6c7c84;
    }
  }
}

.city-dd .mat-select-min-line {
  color: $dark-primary-text;
  font-weight: 700;
  font-size: 1.125rem;
}

.probabilistic-frisk-index {
  .variation-five .mat-form-field-infix {
    width: 95px;
    padding: 0px 12px;
  }

  .variation-five .mat-select-min-line {
    color: $dark-primary-text;
  }
}

//Export data
.export-data-button {
  .variation-five {
    span {
      font-size: 1rem;
    }
  }
}

.select-region.variation-five .mat-select-min-line {
  font-size: 1.125rem;
  color: #000305;
  font-weight: 700;
}

.region-dd {
  .variation-five .mat-select-min-line {
    font-size: 1.0625rem;
    color: $dark-secondary-text;
    font-weight: 700;
  }
}

//weighted dropdown css
.weighted.component {
  .variation-five .mat-select-min-line {
    font-size: 0.875rem;
    color: $dark-primary-text;
    font-weight: 500;
  }
}

.iso-country-region-weighted-temperature-charts.component {
  .variation-five .mat-select-min-line {
    font-size: 10px;
    color: #6c7c84;
  }

  .variation-five .mat-form-field-infix {
    padding: 0px 4px;
    margin-left: 5px;
  }
}

.dropdownVariationClass {
  .weighted-dropdown-panelClass {
    &.mat-select-panel {
      padding-top: 10px;
      padding-bottom: 10px;

      .mat-option {
        height: 1.9rem;
      }
    }
  }
}
.widget-panel {
  .variation-five .mat-form-field-infix {
    // width: auto;
    height: 25px;
    padding: 0px 12px;
    font-family: Plus Jakarta Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.widget-panel-smaller {
  .variation-five .mat-form-field-infix {
    height: 25px;
    padding: 0px 12px;
    font-family: Plus Jakarta Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.widget-interval-speed-selector {
  .variation-five .mat-form-field-infix {
    width: 121px;
    height: 20px;
    padding: 0px 12px;
    font-family: Plus Jakarta Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.model-control-dropdown {
  .variation-five {
    .mat-select-arrow-wrapper {
      margin-bottom: 0.125rem;
      padding-bottom: 0;
      background-image: url('/assets/icons/arrow-down-black.svg');
    }

    &.mat-focused .mat-select-arrow-wrapper {
      margin-top: 0.375rem;
    }

    .mat-form-field-infix {
      margin-top: 0;
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
    }
  }

  .variation-four {
    .mat-form-field-infix {
      margin-top: 0;
      padding: 0.313rem 0.75rem;
      font-size: 0.75rem;
      line-height: 21px;
    }
  }
}

.model-weighted-forecast-charts-rundates-labels.component {
  .variation-five .mat-form-field-infix {
    padding: 3px 4px;
  }
  .no-padding-panel {
    padding: 0;
  }
}

.fresh-look,
.na-renewables {
  .variation-five {
    .mat-form-field-infix {
      margin-top: 0;
      padding: 0.313rem 0.625rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: unset;
    }

    .mat-select-value-text {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: unset;
    }

    .mat-select-min-line {
      color: inherit;
    }

    .mat-select-arrow-wrapper {
      margin-top: 0;
      margin-bottom: 0.125rem;
      padding-bottom: 0;
      background-image: url('/assets/icons/arrow-down-black.svg');
      transform: rotate(0deg);
      transition: transform 0.1s linear;
    }

    &.mat-focused .mat-select-arrow-wrapper {
      margin-top: 0.375rem;
      transform: rotate(180deg);
    }
  }
}

.forecast-evolution-chart-secondary-lens {
  .variation-five {
    .mat-form-field-infix {
      height: 0.625rem;
      margin-top: 0;
      padding: 0.313rem 0.625rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1em;
    }

    .mat-select-value-text {
      font-size: 0.625rem;
      font-weight: 500;
      line-height: 1em;
      color: mat.get-color-from-palette(customTheme.$grey-palette, 450);
    }

    .mat-select-min-line {
      color: inherit;
    }

    .mat-select-arrow-wrapper {
      margin-top: 0;
      margin-bottom: 0.125rem;
      padding-bottom: 0;
      background-image: url('/assets/icons/arrow-down-black.svg');
      transform: rotate(0deg);
      transition: transform 0.1s linear;
    }

    &.mat-focused .mat-select-arrow-wrapper {
      margin-top: 0.375rem;
      transform: rotate(180deg);
    }
  }
}
