.heat-map {
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  .table-cell {
    border: 2px solid #ffffff;
    height: 18px;
    background-color: #d5e4ef;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    color: #000305;

    &:first-child {
      overflow: initial;
    }

    &.threshold-cell {
      height: 100%;
    }

    &.ag2-normals-cell {
      background: none;
    }

    &.without-background {
      background: none;
    }
  }

  .table-header-cell {
    text-align: center;
    font-size: 0.45rem;

    .date {
      text-align: center;
      background-color: transparent;
      font-size: 10px;
      font-weight: 400;
      height: 12px;
      color: customTheme.$dark-primary-text;
    }

    .widget-date {
      font-size: 10px;
      font-weight: 400;
      text-align: center;

      color: customTheme.$dark-primary-text;
    }

    .widget-normals {
      margin-right: 8px;
    }

    .widget-day {
      text-align: center;
      background-color: transparent;
      font-size: 10px;
      font-weight: 700;
      color: customTheme.$dark-secondary-text;
    }

    .day {
      text-align: center;
      background-color: transparent;
      font-size: 10px;
      font-weight: 700;
      color: customTheme.$dark-secondary-text;
    }

    &.fcv-hourly,
    &.fd-hourly {
      top: 5px;
    }
  }

  .first-col-cell {
    background-color: transparent;
    height: 26px;
    font-size: 0.875rem;
    color: customTheme.$dark-primary-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &-secondary {
      color: customTheme.$dark-secondary-text;
      font-size: 12px;
      line-height: 18px;
      margin-right: 10px;
      text-align: right;
    }
  }

  .ag-header-row {
    overflow: initial;
  }

  &.only-header {
    background-color: transparent;
    position: relative;
  }

  &.grey-bg {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 12px;
      height: 38px;
      background: #f7f8f8;
      width: 100%;
    }
  }
  .period-date-header {
    font-size: 0.625rem;

    .period {
      color: customTheme.$dark-primary-text;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .period-date {
      text-transform: uppercase;
      color: customTheme.$dark-secondary-text;
      font-weight: 700;
    }
  }

  .overall-regions.is-all-regions {
    .overall-all-regions-location {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.heat-map-footer {
  display: grid;
  padding: 5px 10px;
  background-color: mat.get-color-from-palette($grey-palette, 150);

  .right {
    justify-self: right;
  }

  &.temperature {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.precip {
    .right {
      .precip-component {
        margin-left: 45px;
      }
    }
  }
}

/* selectable-grid */
.selectable-grid {
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  color: #000305;

  .ag-center-cols-container {
    width: 100%;
  }

  .ag-row {
    cursor: pointer;

    &.ag-row-hover {
      background-color: #f4f5f6;
    }

    &.ag-row-focus {
      background-color: #f4f5f6;
    }

    &.ag-row-selected {
      background-color: #f4f5f6;
    }
  }
}
.ag-overlay-loading-wrapper {
  background-color: rgba(255, 255, 255, 0.66);
}

.tooltip-heat-map-summary {
  .tooltip-date {
    width: 100%;
    color: mat.get-color-from-palette($grey-palette, 450);
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-family: customTheme.$defaultFontFamily, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  .tooltip-details {
    display: flex;
    font-family: customTheme.$defaultFontFamily, sans-serif;
    justify-content: space-between;

    .tooltip-label {
      margin-right: 2px;
      font-size: 12px;
      margin-bottom: 3px;
      color: customTheme.$dark-secondary-text;
    }

    .tooltip-temperature {
      font-size: 0.75rem;
      color: customTheme.$dark-primary-text;
      font-weight: 700;
    }
  }
}

.slash-value {
  height: 20px;
  display: flex;
  font-size: 10px;
  border-radius: 5px;

  div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
}

// show ... icon whenever row is hovered on overview heat map
.overview-heat-map-region-view,
.overview-heat-map-city-view {
  margin: 3px 0;
  .ag-row-hover {
    .popover-menu {
      color: #000000 !important;
    }
  }
}

.forecast-analysis-heat-map.component {
  $cell-height: 20px;

  .table-cell {
    height: $cell-height;
    border-style: solid;
    border-color: mat.get-color-from-palette($grey-palette, 50);
    border-width: 0;
  }

  .first-col-cell {
    height: $cell-height;
    justify-content: flex-end;
  }

  .with-column-borders {
    & .first-column-cell {
      & .table-cell {
        border-width: 0 2px;
      }
    }

    & .border-left-cell {
      border-left: 2px solid mat.get-color-from-palette($grey-palette, 425);

      & .table-cell {
        border-width: 0 4px 0 2px;
      }
    }

    & .table-header-cell {
      border-bottom: 20px solid mat.get-color-from-palette($grey-palette, 50);
    }
  }
}

/*Location heatmap heading*/
.location-heat-map {
  &.grid-container {
    margin-top: 10px;
    .heading {
      font-size: 0.875rem;
      color: customTheme.$dark-primary-text;
      padding-bottom: 4px;
      margin-top: -4px;
      margin-left: 4px;
      position: sticky;
      top: 0;
      z-index: 1;

      &.main-head {
        height: 18px;
        width: calc(100% - 6px);
      }

      .temperature {
        position: relative;
        top: 45px;
      }
    }

    .heading.main-head {
      width: calc(100% - 16px);
    }

    .grid {
      width: calc(100% - 4px);
      height: calc(100% + 8px);
      margin-bottom: 12px;
      // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
      //   0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border-top-left-radius: 8px;
      margin-left: 4px;
      box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.06);
      .heat-map-footer.temperature {
        .left {
          > span {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.row-separator {
  position: absolute;

  &:after {
    content: '';
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 1px;
    background-color: mat.get-color-from-palette($grey-palette, 600);
  }
}

.scroll-table {
  overflow-y: auto;
  max-height: 51vh;
  scrollbar-color: #e9e9eb transparent;

  &.scroll-space {
    height: calc(100vh - 340px);
    max-height: none;
  }
  &.scroll-space-short {
    height: calc(100vh - 420px);
    max-height: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 6px;
    background: #e9e9eb;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6px;
  }
}

@media (max-height: 750px) {
  .scroll-table {
    max-height: 41vh;
  }
}
.forecast-analysis-heat-map-cell.component.table-cell {
  font-size: 0.6875rem;
}

.widget-forecast-analysis .ag-body-viewport.ag-layout-normal {
  overflow-y: hidden;
}

.CDDiffBelowZero {
  background-color: #b1cde3;
  color: $dark-primary-text;
}
.CDDiffAboveZero {
  background-color: #d9b8ba;
  color: $dark-primary-text;
}
.HDDiffBelowZero {
  background-color: #d9b8ba;
  color: $dark-primary-text;
}

.HDDiffAboveZero {
  background-color: #b1cde3;
  color: $dark-primary-text;
}
